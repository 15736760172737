import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faBuilding, faMobileAlt, faMoneyBillWave, faCreditCard, faDesktop } from '@fortawesome/free-solid-svg-icons';

class ServiceArea extends Component<{}, {}> {
  render() {
    return (
      <div className="service-area default-pd">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title">
                <h6 className="subtitle subtitle-thumb">Best Services</h6>
                <h2 className="title">Presenting Banking Plan & Services That are Right For You</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-service">
                <div className="thumb">
                  <FontAwesomeIcon icon={faGlobe} size="3x" />{' '}
                </div>
                <div className="service-details">
                  <h5>
                    <Link className="initiate-scripts" to={'/services'}>
                      Online Business
                    </Link>
                  </h5>
                  <p>The best for helping in growing businesses. Online business banking gives you the ability to manage your business account.</p>
                  <Link className="angle-btn initiate-scripts" to={'/services'}>
                    <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service">
                <div className="thumb">
                  <FontAwesomeIcon icon={faBuilding} size="3x" />{' '}
                </div>
                <div className="service-details">
                  <h5>
                    <Link className="initiate-scripts" to={'/services'}>
                      Business Plan
                    </Link>
                  </h5>
                  <p>Helps businesses find active buyers, increase channels, and new markets.</p>
                  <Link className="angle-btn initiate-scripts" to={'/services'}>
                    <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service">
                <div className="thumb">
                  <FontAwesomeIcon icon={faMobileAlt} size="3x" />{' '}
                </div>
                <div className="service-details">
                  <h5>
                    <Link className="initiate-scripts" to={'/services'}>
                      Mobile Bank
                    </Link>
                  </h5>
                  <p>Mobile Banking applicate with all new features.</p>
                  <Link className="angle-btn initiate-scripts" to={'/services'}>
                    <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service">
                <div className="thumb">
                  <FontAwesomeIcon icon={faMoneyBillWave} size="3x" />{' '}
                </div>
                <div className="service-details">
                  <h5>
                    <Link className="initiate-scripts" to={'/services'}>
                      Online Deposit
                    </Link>
                  </h5>
                  <p>Deposits are adjusted upon review.</p>
                  <Link className="angle-btn initiate-scripts" to={'/services'}>
                    <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service">
                <div className="thumb">
                  <FontAwesomeIcon icon={faCreditCard} size="3x" />{' '}
                </div>
                <div className="service-details">
                  <h5>
                    <Link className="initiate-scripts" to={'/services'}>
                      Credit Card
                    </Link>
                  </h5>
                  <p>Connect one on one with a credit card specialist.</p>
                  <Link className="angle-btn initiate-scripts" to={'/services'}>
                    <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service">
                <div className="thumb">
                  <FontAwesomeIcon icon={faDesktop} size="3x" />{' '}
                </div>
                <div className="service-details">
                  <h5>
                    <Link className="initiate-scripts" to={'/services'}>
                      Income Monitoring
                    </Link>
                  </h5>
                  <p>Smart tools help you make smart money decisions.</p>
                  <Link className="angle-btn initiate-scripts" to={'/services'}>
                    <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceArea;
