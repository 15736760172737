import React, {Component} from "react";

type ClientAreaState={

}

class ClientArea extends Component<{},ClientAreaState> {
    render() {
        return (
            <div className="client-area pd-bottom-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="section-title">
                                <h6 className="subtitle subtitle-thumb">Valuable Stpeech</h6>
                                <h2 className="title">What Our Client Say?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="client-slider">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="client-slider-item">
                                            <div className="media">
                                                <img src="content/assets/img/client/1.png" alt="client" />
                                                <div className="media-body">
                                                    <h6>Robert Spears</h6>
                                                    <p>CTO of Bank</p>
                                                </div>
                                            </div>
                                            <p className="client-content">Robert Spears serves as CTO. In this role, Robert is responsible for working with our Deep boards and focused on our global integrated strategy. He assists a wide range of international clients.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="client-slider-item">
                                            <div className="media">
                                                <img src="content/assets/img/client/2.png" alt="client" />
                                                <div className="media-body">
                                                    <h6>Bruce Rogers</h6>
                                                    <p>CEO at Bank</p>
                                                </div>
                                            </div>
                                            <p className="client-content">Bruce Rogers of CEO DeepBank is responsible for operations and policy.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientArea;