import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faCreditCard, faArrowsAltH, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

class MoneyOption extends Component<{}, {}> {
  render() {
    return (
      <div className="money-option-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-work mt-0 text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faArrowDown} size="lg" />
                </div>
                <h5>
                  <Link className="initiate-scripts" to={'/apply-loan'}>
                    Withdraw
                  </Link>
                </h5>
                <p>Get instant cash with our full service from ATM or within the branch.</p>
                <Link className="angle-btn initiate-scripts" to={'/apply-loan'}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-work mt-md-0 text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faArrowRight} size="lg" />{' '}
                </div>
                <h5>
                  <Link className="initiate-scripts" to={'/apply-loan'}>
                    Send Money
                  </Link>
                </h5>
                <p>Send money or pay bills based on your budget with installment.</p>
                <Link className="angle-btn initiate-scripts" to={'/apply-loan'}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-work text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faCreditCard} size="lg" />
                </div>
                <h5>
                  <Link className="initiate-scripts" to={'/apply-loan'}>
                    Cards
                  </Link>
                </h5>
                <p>Create an account and get your card for a credit or debit account.</p>
                <Link className="angle-btn initiate-scripts" to={'/apply-loan'}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-work text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faArrowsAltH} size="lg" />{' '}
                </div>
                <h5>
                  <Link className="initiate-scripts" to={'/apply-loan'}>
                    Exchange
                  </Link>
                </h5>
                <p>You can exchange foreign currency with a single click.</p>
                <Link className="angle-btn initiate-scripts" to={'/apply-loan'}>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoneyOption;
