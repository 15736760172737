import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faShieldAlt, faMoneyCheckAlt, faCoins } from '@fortawesome/free-solid-svg-icons';

class WorkArea extends Component<{}, {}> {
  render() {
    return (
      <div className="work-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title section-title-2">
                <h6 className="subtitle subtitle-thumb">Best Services</h6>
                <h2 className="title">How It Works</h2>
                <p>
                Our bank offers products and services to help you manage your money.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-work mt-0 text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faUserAlt} size="2x" />
                </div>
                <h5>
                  <Link className=" initiate-scripts" to={'/services'}>
                    Know About
                  </Link>
                </h5>
                <p>We want to satisfy our customers` services needs.</p>
                <Link className="angle-btn initiate-scripts" to={'/services'}>
                  <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-work mt-md-0 text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faShieldAlt} size="2x" />
                </div>
                <h5>
                  <Link className=" initiate-scripts" to={'/services'}>
                    Create Account
                  </Link>
                </h5>
                <p>Create and help protect your accounts.</p>
                <Link className="angle-btn initiate-scripts" to={'/services'}>
                  <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-work text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faMoneyCheckAlt} size="2x" />
                </div>
                <h5>
                  <Link className=" initiate-scripts" to={'/services'}>
                    Start Invest
                  </Link>
                </h5>
                <p>Plan your investments and manage your investments.</p>
                <Link className="angle-btn" to={'/services'}>
                  <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-work text-center">
                <div className="work-icon">
                  <FontAwesomeIcon icon={faCoins} size="2x" />
                </div>
                <h5>
                  <Link className=" initiate-scripts" to={'/services'}>
                    Get Profit
                  </Link>
                </h5>
                <p>Plan your investments and manage your investments.</p>
                <Link className="angle-btn initiate-scripts" to={'/services'}>
                  <img src="content/assets/img/icon/angle-left-round.png" alt="icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkArea;
